import Control from '@/js/Controls/Control'
import { tns } from 'tiny-slider/src/tiny-slider'
import { TinySliderInstance, TinySliderSettings } from 'tiny-slider'
import React from 'jsx-dom'
import { deepMerge } from 'collapsable.js'

interface HTMLCarouselElement extends HTMLElement {
	carousel: TinySliderInstance
}

class CarouselControl implements Control {
	public initialize(context: Element | Document): void {
		const carousels = context.querySelectorAll<HTMLElement>('.js-carousel')

		carousels.forEach((carouselWrapper) => {
			const carouselElement = carouselWrapper.querySelector<HTMLElement>('.js-carousel__carousel')
			const itemsCount = carouselElement ? carouselElement.querySelectorAll('.js-carousel__item').length : 0

			if (itemsCount <= 1 || !carouselElement) {
				return
			}

			const dataOptions = JSON.parse(carouselWrapper.dataset.carousel || '{}')
			const controls = this.buildControlsContainer(carouselWrapper)
			const nav = this.buildNavContainer(carouselWrapper, itemsCount)

			const defaultOptions: TinySliderSettings = {
				container: carouselElement,
				controlsContainer: controls,
				navContainer: nav,
				loop: true
			}

			const options = deepMerge({}, defaultOptions, dataOptions)

			;(carouselWrapper as HTMLCarouselElement).carousel = tns(options)

			if ((carouselWrapper as HTMLCarouselElement).classList.contains('js-carousel--names')) {
				;(carouselWrapper as HTMLCarouselElement).carousel.events.on('indexChanged', () =>
					this.checkVisibleName(
						nav,
						nav.parentElement,
						(carouselWrapper as HTMLCarouselElement).carousel.getInfo().index
					)
				)
			}
		})
	}

	private checkVisibleName(nav: HTMLElement, parent: HTMLElement | null, index: number) {
		const wrapWidth = nav.offsetWidth
		const page = nav.children.item(index - 1) as HTMLElement

		if (page && parent) {
			const pageWidth = page.offsetWidth
			const pageLeft = page.offsetLeft

			if (pageLeft + pageWidth < parent.scrollLeft) {
				parent?.scrollTo({
					top: 0,
					left: pageLeft,
					behavior: 'smooth'
				})
			}

			if (pageWidth + pageLeft > wrapWidth + parent.scrollLeft) {
				parent?.scrollTo({
					top: 0,
					left: pageWidth + pageLeft - wrapWidth,
					behavior: 'smooth'
				})
			}
		}
	}

	private buildControlsContainer(carouselWrapper: HTMLElement): HTMLElement {
		const controls = carouselWrapper.querySelector<HTMLElement>('.js-carousel__controls')
		const prevLang = carouselWrapper.dataset.prev
		const nextLang = carouselWrapper.dataset.next

		if (controls) {
			return controls
		}

		const customControls: Element = (
			<p class="js-carousel__controls">
				<button type="button" class="js-carousel__control js-carousel__control--prev">
					<span class="sr-only">{prevLang}</span>
				</button>
				<button type="button" class="js-carousel__control js-carousel__control--next">
					<span class="sr-only">{nextLang}</span>
				</button>
			</p>
		)

		carouselWrapper.insertAdjacentElement('afterbegin', customControls)

		return customControls as HTMLElement
	}

	private buildNavContainer(carouselWrapper: HTMLElement, itemsCount: number): HTMLElement {
		const nav = carouselWrapper.querySelector<HTMLElement>('.js-carousel__nav')

		if (nav) {
			return nav
		}

		const customNav: HTMLElement = (<p class="js-carousel__paging"></p>) as HTMLElement

		for (let i = 0; i < itemsCount; i++) {
			customNav.appendChild(<button type="button" class="js-carousel__page" style="display: none"></button>)
		}

		carouselWrapper.insertAdjacentElement('beforeend', customNav)

		return customNav
	}
}

export default new CarouselControl()
