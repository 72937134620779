import Control from '@/js/Controls/Control'

class ElementSizeVariablesControl implements Control {
	private observer: ResizeObserver
	public selectorList: string[]

	public constructor(selectorList: string[]) {
		this.observer = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				const element = entry.target as HTMLElement
				document.documentElement.style.setProperty(
					`--${element.dataset.sizeVariable}`,
					element.offsetHeight + 'px'
				)
			})
		})

		this.selectorList = selectorList
	}

	public initialize(context: Element | Document) {
		this.selectorList.forEach((selector) => {
			const elements = context.querySelectorAll<HTMLElement>(selector)

			elements.forEach((element) => this.observer.observe(element))
		})
	}
}

export default new ElementSizeVariablesControl(['.warning-bar', '.product-buy-bar'])
