import ControlManager from '@/js/Controls/ControlManager'

import CarouselControl from '@/js/Controls/CarouselControl'
import CollapsableControl from '@/js/Controls/CollapsableControl'
import ElementSizeVariablesControl from '@/js/Controls/ElementSizeVariablesControl'
import MainMenuControl from '@/js/Controls/MainMenuControl'
// import EqualHeightsControl from '@/js/Controls/EqualHeightsControl'
// import TooltipControl from '@/js/Controls/TooltipControl'

ControlManager.addControlOnLive(CarouselControl)
ControlManager.addControlOnLive(CollapsableControl)
ControlManager.addControlOnLive(ElementSizeVariablesControl)
ControlManager.addControlOnLoad(MainMenuControl)
// ControlManager.addControlOnLive(EqualHeightsControl)
// ControlManager.addControlOnLive(TooltipControl)

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', () => ControlManager.onLoad())
} else {
	ControlManager.onLoad()
}
